@import '~./src/themes/default/custom/_variables.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.enablewebgpu {
  &__header {
    color: $twilight;
  }

  &__description {
    color: $twilight;
  }

  &__browsers {
    display: grid;
    grid-template: auto / 1fr;
    gap: 1.5rem;

    @include media-breakpoint-up(md) {
      grid-template: auto / repeat(3, 1fr);
    }
  }
}

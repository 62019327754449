.model-select-wrapper {
  &__inputs {
    display: flex;
    align-items: center;

    button {
      margin-left: 1rem;
      padding: 0.25rem 0.5rem;
      align-self: flex-start;
      margin-top: 0.25rem;
    }
  }

  &__dropdown {
    width: 70%;
  }
}

.model-select__control {
  height: 42px;
}

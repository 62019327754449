@mixin button-basic {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  display: inline-block;
}

@mixin button-blue {
  @include button-basic;
  color: $white;
  border: 1px solid $information;
  background-color: $information;
}

@mixin button-white {
  @include button-basic;
  color: $information;
  border: 1px solid $information;
  background-color: $white;
}

@mixin button-black {
  @include button-basic;
  color: $white;
  border: 1px solid $white;
  background-color: $into-black;
}

@mixin button-green {
  @include button-basic;
  color: $white;
  border: 1px solid $success;
  background-color: $success;
}

@mixin button-red {
  @include button-basic;
  color: $white;
  border: 1px solid $danger;
  background-color: $danger;
}

@mixin button-twilight {
  @include button-basic;
  color: $twilight;
  border: 1px solid $twilight;
  background-color: $white;
}

@mixin button-sack-cloth {
  @include button-basic;
  color: $white;
  border: 1px solid $sack-cloth;
  background-color: $sack-cloth;
}

@mixin button-masonry {
  @include button-basic;
  color: $gray-900;
  border: 1px solid $masonry;
  background-color: $masonry;
}

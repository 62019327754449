@import '~./src/themes/default/custom/_variables.scss';

.anyquestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $twilight;

  &__description {
    max-width: 680px;
    text-align: center;
  }

  a {
    color: $twilight;
  }
  a:hover {
    color: $twilight;
  }
}

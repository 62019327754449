html {
  -webkit-font-smoothing: antialiased;
  position: relative;
  min-height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sr-only {
  display: none;
}

h1 {
  font-size: 50px;
  font-weight: 700;
}

h2 {
  font-size: 36px;
  font-weight: 700;
}

h3 {
  font-size: 32px;
  font-weight: 700;
}

h4 {
  font-size: 28px;
  font-weight: 700;
}

h5 {
  font-size: 24px;
  font-weight: 700;
}

.btn {
  padding: 0.3rem 1rem !important;
  font-size: 14px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  border: none;
  background-color: white;
  padding: 1.5rem;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.font-100 {
  font-weight: 100;
}

.font-400 {
  font-weight: 400;
}

.font-700 {
  font-weight: 700;
}

@import '~./src/themes/default/custom/_variables.scss';

.custom-option {
  border-bottom: 1px solid $sack-cloth;
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &__selected {
    background-color: $information-dark;
    color: $white;
  }

  &:focus,
  &:hover {
    background-color: $information-light;
    color: $gray-900;
  }

  img {
    margin-right: 1rem;
  }
}

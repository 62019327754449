@import '~./src/themes/default/custom/_variables.scss';
@import '~./src/themes/default/custom/_mixins.scss';

.actionbutton {
  white-space: nowrap;
}

@mixin action-buttons {
  display: flex;
  align-items: center;

  &:hover {
    color: $white;
  }
}

.stop-button {
  @include button-red;
  @include action-buttons;
  white-space: nowrap;
}

.proceed-button {
  @include button-green;
  @include action-buttons;
}

.cancel-button {
  @include button-twilight;
  margin-right: 1rem;
}

.twilight-button {
  @include button-twilight;
  background-color: transparent;
}

.action-button {
  @include button-red;
}

.disabled-button,
.stopped-button {
  @include button-sack-cloth;
}

.model-button {
  @include button-masonry;
  background-color: $white;
}

.close-button,
.save-button {
  @include button-blue;
}

@import '~./src/themes/default/custom/_variables.scss';

.timelineforsupport {
  background: $bg-blue;
  color: $white;

  &__cards {
    background: $bg-blue;
    justify-content: space-between;
  }

  &__header {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

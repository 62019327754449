@import '~./src/themes/default/custom/_variables.scss';

header {
  position: relative;
  background: $into-black;

  .navbar {
    height: 114px;
    display: flex;
    justify-content: flex-start;

    &__basetext {
      color: $white;
      font-size: 1.7rem;
      font-weight: 700;
      padding-left: 1rem;
      position: relative;
    }
  }
  &:before {
    content: 'beta';
    position: absolute;
    width: 80px;
    height: 25px;
    background: $information;
    top: 7px;
    left: -20px;
    text-align: center;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    line-height: 27px;
    transform: rotate(-45deg);
  }

  .version {
    font-size: 0.8rem;
    text-decoration: none;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.select-wrapper {
  border: 1px solid $masonry;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  label {
    display: block;
    color: $twilight;
    font-size: 0.5rem;
    margin: 0 0 0.25rem 0.25rem;
  }

  select {
    width: 100%;
    border: none;
    background: none;

    > option {
      padding-left: 10px;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.hiw-wrapper {
  text-align: center;
  background: $white;

  &__title {
    color: $twilight;
    font-weight: 600;
  }

  .hiw-container {
    justify-content: center;
    align-items: flex-start;
  }

  .arrow {
    max-height: 40px;
    max-width: 120px;

    @include media-breakpoint-up(lg) {
      max-width: 150px;
    }
  }
}

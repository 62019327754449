@import '~./src/themes/default/custom/_variables.scss';

.batch-steps {
  &__see-all {
    cursor: pointer;
    color: $information;
    user-select: none;
  }

  &.batch-steps__show-all {
    .see-all-icon {
      transform: rotate(180deg);
    }
  }

  &__list {
    padding-left: 0;
    margin-bottom: 0;

    li {
      position: relative;
      display: block;
      padding: 0.5rem 1rem;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid $sack-cloth;

      & + li {
        border-top-width: 0;
      }

      &.batch-steps__item_success {
        background-color: $success-light;
      }
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';
@import '~./src/themes/default/custom/_mixins.scss';

.process-title {
  background-color: $bg-blue;
  color: $white;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 > span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-child {
      width: 50%;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.wp-card {
  color: $white;
  text-align: left;
  display: flex;
  align-items: flex-start;

  &__body {
    max-width: 270px;
    margin-left: 1.5rem;
    align-items: center;
  }

  &__card {
    margin: 0 20px;
  }
}

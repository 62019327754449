@import '~./src/themes/default/custom/_variables.scss';

.progress-bar.progress-completed {
  background-color: transparent;
  border-radius: 0.25rem;

  .progress-bar__multi {
    background-color: $white;
    height: 1.5rem;

    &__success {
      background-color: $success;
    }

    &__stopped {
      background-color: $sack-cloth;
    }

    &__error {
      background-color: $danger;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.process-list {
  position: relative;
  min-height: 100px;
  overflow-y: hidden;
  height: 150px;
  overflow: hidden;

  &__show-all {
    height: 100%;
  }

  &__header {
    span {
      color: $information-dark;
      user-select: none;

      &.see-all {
        cursor: pointer;
        color: $information;
      }
    }
  }

  &.process-list__show-all {
    .see-all-icon {
      transform: rotate(180deg);
    }
  }

  &:not(.process-list__show-all):after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(228, 230, 232, 1) 90%);
    width: 100%;
    height: 3em;
  }

  .ReactModal__Body--open &:after {
    background-image: none;
  }

  .row {
    margin-bottom: 1rem;
  }

  &__remove_all-text {
    cursor: pointer;
    color: $danger !important;
  }

  &__remove_single-icon {
    cursor: pointer;
  }

  &__filename {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

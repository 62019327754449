@import '~./src/themes/default/custom/_variables.scss';

.timelinecards {
  padding-left: 1rem;

  &:first-child {
    padding-left: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 303px;
  }

  &__image {
    height: 25px;
    width: 25px;
    margin: 10px;
  }
  &__title {
    font-size: 16px;
  }

  &__description {
    text-align: center;
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $information-light;
  border: 2px dashed $information;
  outline: none;
  cursor: pointer;

  &.dropzone-active {
    border: 2px solid $information;
  }  
  
  .dropzone-content {
    font-size: 28px;
    font-weight: bold;
    color: $twilight;
    margin-bottom: 0;

    > span {
      display: block;
      font-size: 1rem;
      font-weight: normal;
      text-decoration: underline;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.model-modal {
  width: 70%;
  max-height: 80vh;
  overflow: auto;
  padding: 1.5rem;

  &__selected_model {
    position: relative;

    span {
      font-weight: normal;
    }

    img {
      position: absolute;
      right: 0;
      top: -0.25rem;
    }
  }

  &__number {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: $information;
    border-radius: 24px;
    color: $white;
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    margin-right: 1.25rem;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;

    &:before,
    &:after {
      position: absolute;
      right: 13px;
      content: ' ';
      height: 28px;
      width: 5px;
      background-color: $information;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__save {
    display: flex;
    justify-content: space-between;

    button {
      align-self: center;
    }
  }
}

.model-model__section {
  padding: 1rem;
  position: relative;
  padding-left: 84px;
  background-color: $information-light;

  &_heading {
    display: flex;
    align-items: center;
  }

  &_content {
    columns: 2;
    column-gap: 1.25rem;

    &.single-col {
      columns: 1;
      width: calc(50% - (1.25rem / 2));
    }
  }

  &_button {
    border-radius: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;

    img {
      width: 16px;
      position: absolute;
      right: 1rem;
    }

    &.active {
      border: 1px solid $success-dark;
      background-color: $success-light;
    }
  }

  &.selected-section {
    background-color: transparent;
    padding-top: 1.75rem;
    cursor: pointer;
  }

  &.disabled {
    .model-modal__number {
      background-color: $sack-cloth;
    }
    span {
      color: $sack-cloth;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.staticgpuDetection {
  background-color: $danger-light;
  display: flex;
  border-radius: 20px;
  height: 36px;
  align-items: center;

  > p,
  h5 {
    color: $danger;
    font-size: 14px;
    font-weight: 400;
  }

  &.active {
    background-color: $success-light;

    > p,
    h5 {
      color: $success-dark;
    }
  }

  .gpuicon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
}

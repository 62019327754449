@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');

/*Theme Colors*/
$twilight: #455556;
$masonry: #e4e6e8;
$into-black: #001a21;
$danger: #a90f03;
$danger-light: #fdeae9;
$success: #17bb75;
$success-dark: #007543;
$success-light: #e6f7f0;
$information: #0084a9;
$information-dark: #003e5e;
$information-light: #e4f1f5;
$bg-blue: #005c75;
$sack-cloth: #b5aea7;
/* ***************************** */
// Bootstrap overrides
/****************************** */

$white: #fff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;
$gray-700: #525f7f !default;
$gray-800: #32325d !default;
$gray-900: #212529 !default;
$black: #000 !default;
$cyan: #4fc3f7 !default;
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': #5e72e4,
    'secondary': $gray-600,
    'success': #2dce89,
    'info': #2962ff,
    'warning': #fb6340,
    'danger': #f62d51,
    'light': $gray-100,
    'dark': $gray-800,
    'cyan': #4fc3f7,
    'orange': #fb6340,
    'purple': #5e72e4,
    'inverse': #32325d,
  ),
  $theme-colors
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    //4px
    2:
      (
        $spacer * 0.5,
      ),
    //8px
    3: $spacer,
    //16px
    4:
      (
        $spacer * 1.5,
      ),
    //24px
    5:
      (
        $spacer * 2,
      ),
    //32px
    6:
      (
        $spacer * 3,
      ),
    //48px
    7:
      (
        $spacer * 4,
      ),
    //64px
  ),
  $spacers
);

// Shadows
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);

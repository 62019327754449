@import '~./src/themes/default/custom/_variables.scss';

.gpuDetection {
  background-color: $danger-light;

  > p,
  h5 {
    color: $danger;
  }

  &.active {
    background-color: $success-light;

    > p,
    h5 {
      color: $success-dark;
    }

    span {
      font-size: 10px;
      font-weight: 700;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.browsercards {
  color: $twilight;
  background-color: $information-light;
  border-radius: 1rem;
  padding: 1.5rem;

  &__header {
    color: $black;
    justify-content: center;
    align-items: flex-start;
  }

  &__description {
    flex-direction: column;
  }

  &__image {
    height: 30px;
    width: 30px;
  }

  &__text {
    code {
      background: $masonry;
      border-radius: 4px;
      color: $twilight;
      font-size: 1rem;
      padding: 0.2rem 0;
    }
  }

  a {
    color: $twilight;
  }

  a:hover {
    color: $twilight;
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.error-modal {
  max-width: 600px;

  ul {
    margin: 1.5rem 0;

    li {
      margin-bottom: 1rem;
    }
  }
}

@import '~./src/themes/default/custom/_variables.scss';

.aboutwebgpu {
  display: flex;
  flex-direction: column;

  &__description {
    color: $twilight;
    font-size: 16px;
  }

  &__header {
    font-weight: 700;
  }

  &__image {
    img {
      width: 100%;
    }
  }

  a {
    color: $information;
    text-decoration: none;
  }

  a:hover {
    color: $information;
  }
}

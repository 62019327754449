@import '~./src/themes/default/custom/_variables.scss';
@import '~./src/themes/default/custom/_mixins.scss';

.config-wrapper {
  margin-left: auto;

  .config-button {
    @include button-black;

    &:hover {
      color: $white;
    }
  }
}

.modal-container {
  &.config-modal {
    transform: none;
    left: auto;
    right: 20px;
    margin-right: 0;
    top: 96px;
    width: 366px;

    .arrow-top {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid #fff;
      position: absolute;
      top: -10px;
      right: 64px;
    }

    .button-wrapper {
      display: flex;
      justify-content: right;

      &.options-open {
        justify-content: space-between;
      }
    }

    .options-button {
      @include button-white;
      margin-right: 0.5rem;
    }
  }
}

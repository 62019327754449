@import '~./src/themes/default/custom/_variables.scss';

.process-row {
  &__title {
    font-weight: bold;
    color: $information-dark;
  }

  border-bottom: 1px solid $sack-cloth;

  &:last-child {
    border-bottom: none;
  }
}
@import '~./src/themes/default/custom/_variables.scss';

.benchmarkpage {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  &__model {
    display: flex;
    flex-shrink: 2;
    justify-content: space-between;
  }

  &__columntitle {
    background-color: $bg-blue;
    padding: 1rem 0;

    span {
      color: $white;
    }
  }

  &__column {
    background-color: $masonry;
    padding: 0.5rem 0;
  }

  .modelselect {
    width: 1100px;
  }
}

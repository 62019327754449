@import '~./src/themes/default/custom/_variables.scss';

.buttonLink {
  padding: .5rem 1rem;
  border-radius: .25rem;
  text-decoration: none;
  display: inline-block;

    &.danger {  
      color: $danger;
      border: 1px solid $danger;
    }

    &.success {  
      color: $success-dark;
      border: 1px solid $success-dark;
    }
}
@import '~./src/themes/default/custom/_variables.scss';

.webgpubutton {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  align-items: center;
  justify-content: space-around;
  color: $information;
  border: 1px solid $information;
  width: 211px;

  &____text {
    font: 1.5rem;
  }

  &image {
    height: 16px;
    width: 16px;
  }

}

@import '~./src/themes/default/custom/_variables.scss';

.devtools {
  background: $masonry;
  box-shadow: 0 0 20px $gray-700;
  position: absolute;
  width: 300px !important;
  padding: 20px;
  right: 0;
  z-index: 9;

  &.is-closed {
    width: 30px !important;

    .devtools_title {
      display: none;
    }
  }

  &_close-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &_title {
    font-weight: bold;
  }

  &_close-btn {
    font-weight: bold;
    cursor: pointer;
    font-weight: bold;
  }

  .select-wrapper {
    padding: 0;
    margin: 10px 0 0 0;

    label {
      margin: 0;
      font-size: 1em;
    }
  }

  label {
    margin-top: 10px;
  }

  .prop-input {
    display: block;
  }
}

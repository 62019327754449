@import '~./src/themes/default/custom/_variables.scss';

.batch-progress {
  background-color: $masonry;
  border-radius: 0.25rem;

  &__hide_content {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
}
